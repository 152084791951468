import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Wrapper } from "./SignIn.styles";
import { RootState } from "../../stores";
import Default from "../../layouts/auth/Default";
import illustration from "../../assets/image/auth.png";
import AuthActions from "../../stores/actions/authAction";

type LoginInputs = {
  username: string;
  password: string;
};

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

const SignIn = () => {
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );
  const history = useNavigate();
  const dispatch = useDispatch()<any>;

  useEffect(() => {
    const tokens = localStorage.getItem("@userData:token");
    if ((authenticated && token) || tokens) {
      // [ROUTE] Go to dashboard page
      history("/home");
    }
  }, [authenticated, history]);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LoginInputs> = async (data) => {
    await dispatch(
      AuthActions.signIn({ username: data.username, password: data.password })
    );
  };

  return (
    <Wrapper>
      <Default
        illustrationBackground={illustration}
        image={illustration}
        heading="Login"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          // style={{ minHeight: "25vh" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper elevation={3} className="paper">
              <FormControl
                className="textField margin"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  Enter username *
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-username"
                  label={"Enter username *"}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Enter username *"
                  inputProps={{ style: { textAlign: "left" } }}
                  {...register("username")}
                  error={Boolean(errors?.username?.message)}
                />
                {errors?.username?.message && (
                  <FormHelperText error>
                    {errors?.username?.message}
                  </FormHelperText>
                )}
              </FormControl>
              <br />
              <br />
              <FormControl
                className="textField margin"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Enter Password *
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={passwordVisible ? "text" : "password"}
                  label={"Enter Password *"}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Enter Password *"
                  inputProps={{ style: { textAlign: "left" } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        edge="end"
                      >
                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("password")}
                  error={Boolean(errors?.password?.message)}
                />
                {errors?.password?.message && (
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                )}
              </FormControl>

              <br />
              <br />

              <Button
                variant="contained"
                className="loginButton"
                type="submit"
                disabled={isSubmitting}
              >
                Log In
              </Button>
              <br />
              <br />
              <Link
                to={"/forgot-password"}
                style={{ color: "#586EF5", textDecoration: "none" }}
              >
                Forgot Password ?
              </Link>
              <br />
              <br />
              <Link
                to={"/sign-up"}
                style={{ color: "#586EF5", textDecoration: "none" }}
              >
                Create Account
              </Link>

              {/*<a*/}
              {/*  href="#Weglot-en"*/}
              {/*  className="lang_link flex text-slate-800 p-2 hover:bg-slate-100 border-b"*/}
              {/*>*/}
              {/*  English*/}
              {/*</a>*/}

              {/*<a*/}
              {/*  href="#Weglot-fa"*/}
              {/*  className="lang_link flex text-slate-800 p-2 hover:bg-slate-100"*/}
              {/*>*/}
              {/*  فارسی*/}
              {/*</a>*/}
            </Paper>
          </form>
        </Grid>
      </Default>
    </Wrapper>
  );
};

export default SignIn;
