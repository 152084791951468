import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import AuthLayout from "./layouts/auth";
import HomeLayout from "./layouts/home";
import { AlertState } from "./stores/reducers/alertReducer";
import { RootState } from "./stores";

const App = () => {
  const [openalert, setOpenAlert] = React.useState(false);

  const sAlert: AlertState = useSelector((state: RootState) => state.alert);

  useEffect(() => {
    if (sAlert.show) {
      setOpenAlert(true);
    }
  }, [sAlert]);

  // Close alert message
  const onClose = () => {
    setOpenAlert(false);
  };

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path={"/*"} element={<AuthLayout />} />
          <Route path={"home/*"} element={<HomeLayout />} />
        </Routes>
      </BrowserRouter>

      <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={openalert}
        autoHideDuration={5000}
        message={sAlert.message}
        onClose={onClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default App;
