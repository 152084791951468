import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../../views/auth/ForgotPassword";
import SignIn from "../../views/auth/SignIn";
import SignUp from "../../views/auth/SignUp";
import Invited from "../../views/auth/Invited";
import Contactus from "../../views/auth/Contactus";

const Auth = () => {
  return (
    <Routes>
      <Route path={"/"} element={<SignIn />} />
      <Route path={"/forgot-password"} element={<ForgotPassword />} />
      <Route path={"/sign-up"} element={<SignUp />} />
      <Route path={"/invited/:invitedId"} element={<Invited />} />
      <Route path={"/contact-form"} element={<Contactus />} />
    </Routes>
  );
};

export default Auth;
