import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Button } from "@material-ui/core";

import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../../stores/types/alertType";
import { COLORS } from "../../utils/Colors";
import moment from "moment";

export const JoiningRequest = () => {
  const dispatch = useDispatch()<any>;

  const [joiningRequests, setJoiningRequests] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<string | null>(null);

  useEffect(() => {
    const getAvailableCompanies = async () => {
      try {
        const token: string | any = localStorage.getItem("@userData:token");

        const response: any = await axios.get(
          `${BASE_URL}api/v1/company-request/get-join-request`,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (response.data?.status) {
          setJoiningRequests(response.data?.data);
        }
      } catch (e: any) {
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: e?.message,
          },
        });
      }
    };

    getAvailableCompanies();
  }, []);

  const sendRequest = async (id: number, status: string) => {
    try {
      const token: string | any = localStorage.getItem("@userData:token");

      const fd = new FormData();
      fd.append("request_id", String(id));
      fd.append("status", status);

      const response: any = await axios.post(
        `${BASE_URL}api/v1/company-request/update-join-request`,
        fd,
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        }
      );

      if (response.data?.status) {
        setJoiningRequests((prevState) => {
          const index = prevState.findIndex((p: any) => p?.id === id);
          if (index > -1) {
            prevState[index].status =
              status === "REJECTED" ? "REJECTED" : "ACCEPTED";
          }
          return prevState;
        });
      }
    } catch (e: any) {
      dispatch({
        type: AlertTypes.SET_ALERT,
        payload: {
          type: AlertType.WARNING,
          message: e?.message,
        },
      });
    }
  };

  const getStatusButton = (status: string, id: number) => {
    if (status === "ACCEPTED") {
      return (
        <Button
          disabled={true}
          size={"small"}
          style={{
            background: COLORS.basfLightGreen,
            color: COLORS.basfWhite,
          }}
        >
          Accepted
        </Button>
      );
    } else if (status === "REJECTED") {
      return (
        <Button
          disabled={true}
          size={"small"}
          style={{
            background: COLORS.basfRed,
            color: COLORS.basfWhite,
          }}
        >
          Rejected
        </Button>
      );
    } else {
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            disabled={Number(isLoading) === id}
            style={{
              background: COLORS.basfLightGreen,
              color: COLORS.basfWhite,
              padding: "6px 4px",
            }}
            onClick={async () => {
              setIsLoading(String(id));
              await sendRequest(id, "ACCEPTED");
              setIsLoading(null);
            }}
          >
            Accept
          </Button>

          <Button
            disabled={Number(isLoading) === id}
            style={{
              background: COLORS.basfRed,
              color: COLORS.basfWhite,
              padding: "6px 4px",
            }}
            onClick={async () => {
              setIsLoading(String(id));
              await sendRequest(id, "REJECTED");
              setIsLoading(null);
            }}
          >
            Reject
          </Button>
        </div>
      );
    }
  };

  console.log(joiningRequests);

  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Calender"
      icon={<CalendarTodayOutlined />}
    >
      <Grid
        style={{
          width: "70%",
          position: "absolute",
        }}
      >
        <div style={{ background: "white", padding: "6px 12px" }}>
          <h2>Joining Requests</h2>
          <div
            style={{
              borderBottom: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              background: "#dedede",
            }}
          >
            <div style={{ width: "5%" }}>#</div>
            <div style={{ flex: "1 1" }}>
              <b>Customer name</b>
            </div>
            <div style={{ flex: "1 1" }}>
              <b>Customer email</b>
            </div>
            <div style={{ flex: "1 1" }}>
              <b>Project</b>
            </div>
            <div style={{ flex: "1 1" }}>
              <b>Date</b>
            </div>
            <div style={{ flex: "1 1" }}>
              <b>Actions</b>
            </div>
          </div>
          {joiningRequests.length > 0 ? (
            joiningRequests.map((item, index) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div style={{ width: "5%" }}>{index + 1}</div>
                  <div style={{ flex: "1 1" }}>{item.username}</div>
                  <div style={{ flex: "1 1" }}>{item.email}</div>
                  <div style={{ flex: "1 1" }}>{item.company_name}</div>
                  <div style={{ flex: "1 1" }}>
                    {moment(item.date).format("lll")}
                  </div>
                  <div style={{ flex: "1 1" }}>
                    {getStatusButton(item.status, item.id)}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h4 style={{ textAlign: "center", flex: "1 1" }}>No Data</h4>
            </div>
          )}
        </div>
      </Grid>
    </HomeDefault>
  );
};
