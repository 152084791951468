import { io } from "socket.io-client";

export const REGEX = new RegExp(
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
);

const socketUrl: string = process.env.REACT_APP_SOCKET_URL as string;
export const BASE_URL: string = process.env.REACT_APP_API_URL as string;

const opts: {
  transports: string[];
  reconnection: boolean;
  autoConnect: boolean;
  path?: string;
} = {
  transports: ["polling"],
  reconnection: true,
  autoConnect: false,
};

opts.path = "/irandev/";

export const socket = io(socketUrl, opts);

export const validImageTypes = ["gif", "jpeg", "png", "jpg", "svg"];
